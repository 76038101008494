import { useContext } from 'react';
import { Redirect, Route } from 'react-router-dom';
import PropTypes from 'prop-types';

import { clearAuthData, isExpiredJwtToken } from '@uptime/shared/utils/general';

import AuthContext from 'context/AuthContext';
import usePermissions from 'shared/hooks/usePermissions';
import { HOME_PATH } from 'shared/constants/urls';
import BasicMenuItems from 'components/Layout/LeftNav/partials/BasicMenuItems';
import RepairMenuItems from 'components/Layout/LeftNav/partials/RepairMenuItems';

const PrivateRoute = (props) => {
  const { isAllowed, path, ...restProps } = props;
  const { role, isRepairer } = useContext(AuthContext);
  const { hasPermissionsLoaded } = usePermissions();
  const rolesAreLoaded = Boolean(role);

  if (isExpiredJwtToken()) {
    clearAuthData();
    return <Redirect to="/app/login" />;
  }

  if (rolesAreLoaded && hasPermissionsLoaded && !isAllowed) {
    if (path === HOME_PATH) {
      const menuItems = isRepairer ? RepairMenuItems({}) : BasicMenuItems({});
      const firstValirPermission = menuItems.find((item) => Boolean(item.permission));
      if (firstValirPermission) return <Redirect to={`/app/${firstValirPermission.url}`} />;
    }
    return <Redirect to="/app/forbidden" />;
  }

  return <Route {...restProps} />;
};

PrivateRoute.propTypes = {
  path: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.arrayOf(PropTypes.string.isRequired)]),
  isAllowed: PropTypes.bool,
};

export default PrivateRoute;
