import isEmpty from 'lodash/isEmpty';
import { useContext, useEffect } from 'react';
import { useQuery } from '@apollo/client';

import { RUN_TASK_PERSISTED_FILTER } from '@uptime/shared/constants';
import {
  getAccountGroups,
  getAccountProfile,
  getBusinessProfile,
  getUserSettings,
} from '@uptime/shared/graphql/users';
import Splash from '@uptime/shared/components/Splash';
import { getAccountFlows } from '@uptime/shared/graphql/workOrders';
import { getStickyTaskFilterSetting } from '@uptime/shared/utils/settings';
import { getTaskCategories } from '@uptime/shared/graphql/checkList';

import usePermissions from 'shared/hooks/usePermissions';
import { getUserFeatures, getWhiteLabel } from 'graphql/user';
import AuthContext from 'context/AuthContext';
import { SourceContext } from 'providers/SourceProvider';
import { CustomThemeContext } from 'providers/CustomThemeProvider';
import { flowClient, goCheckItClient, userClient, resetGraphQLCache } from 'apolloClientConfiguration';
import { renameFlow } from 'libs/utils/work-order';
import { transformFlowData } from '@uptime/shared/utils/logs';

const SplashScreen = () => {
  const { userId, accountId, isRepairer, partnerId } = useContext(AuthContext);
  const state = useContext(SourceContext);
  const { setPrimaryColorsWL } = useContext(CustomThemeContext);
  const {
    profile,
    setProfile,
    setBusinessProfile,
    setAccountFlows,
    setAccountGroups,
    accountFlows,
    accountGroups,
    taskCategories,
    setTaskCategories,
    setWhiteLabel,
  } = state;

  const {
    setPermissions,
    setHasPermissionsLoaded,
    tasksManagementPermission,
    runTasksPermission,
    commonPermission,
  } = usePermissions();

  useEffect(() => {
    resetGraphQLCache();
  }, []);

  const { loading: permissionsLoading } = useQuery(getUserFeatures, {
    client: userClient,
    skip: !userId,
    onCompleted: (data) => {
      setPermissions((state) => [...state, ...data.getUserFeatures]);
      setHasPermissionsLoaded(true);
    },
  });

  const shouldSkipProfileFetching = (Boolean(userId) && !isEmpty(profile)) || userId === null;
  const shouldGroupsFetching =
    !isEmpty(accountGroups) || userId === null || (!isRepairer && !commonPermission);
  const shouldSkipCategories =
    !isEmpty(taskCategories) || userId === null || !(tasksManagementPermission || runTasksPermission);
  const shouldSkipAccountFlow =
    (Boolean(accountId) && !isEmpty(accountFlows)) || userId === null || (!isRepairer && !commonPermission);

  const { loading: profileLoading } = useQuery(getAccountProfile, {
    variables: {
      userId,
    },
    skip: shouldSkipProfileFetching,
    client: userClient,
    onCompleted: (data) => {
      if (data && data.profile) {
        setProfile(data.profile);
      }
    },
  });

  const { loading: businessProfileLoading } = useQuery(getBusinessProfile, {
    variables: {
      accountId,
    },
    skip: !Boolean(accountId),
    client: userClient,
    onCompleted: (data) => {
      if (data?.businessProfile) {
        setBusinessProfile(data.businessProfile);
      }
    },
  });

  const { loading: whiteLabelLoading } = useQuery(getWhiteLabel, {
    variables: {
      accountId: partnerId,
    },
    skip: !Boolean(partnerId),
    client: userClient,
    onCompleted: (data) => {
      if (data?.whiteLabel) {
        setWhiteLabel(data.whiteLabel);
      }
      if (data?.whiteLabel?.colors) {
        const colors = data.whiteLabel.colors;
        setPrimaryColorsWL(colors);
      }
    },
  });

  const { loading: groupsLoading } = useQuery(getAccountGroups, {
    skip: shouldGroupsFetching,
    client: userClient,
    onCompleted: (data) => {
      if (data && data.accountGroups) {
        setAccountGroups(data.accountGroups);
      }
    },
  });

  const { loading: taskCategoriesLoading } = useQuery(getTaskCategories, {
    skip: shouldSkipCategories,
    client: goCheckItClient,
    variables: {
      userId,
    },
    onCompleted: (data) => {
      if (data && data.taskCategories) {
        setTaskCategories(data.taskCategories);
      }
    },
  });

  const { loading: loadingFlow } = useQuery(getAccountFlows, {
    variables: {
      filter: {
        accountId,
        type: 'workOrder',
        isEnabled: true,
        readOnly: false,
      },
    },
    skip: shouldSkipAccountFlow,
    fetchPolicy: 'cache-first',
    client: flowClient,
    onCompleted: (data) => {
      if (data?.accountFlows) {
        const flows = transformFlowData(data.accountFlows);
        const accountFlows = isRepairer ? flows : flows.map(renameFlow);
        setAccountFlows(accountFlows);
      }
    },
  });

  useQuery(getUserSettings, {
    variables: {
      userId,
    },
    skip: !userId,
    client: userClient,
    onCompleted: ({ settings } = {}) => {
      if (settings) {
        const { value } = getStickyTaskFilterSetting(settings);
        const savedFilter = localStorage.getItem(RUN_TASK_PERSISTED_FILTER);
        if (!savedFilter && value) {
          localStorage.setItem(RUN_TASK_PERSISTED_FILTER, value);
        }
      }
    },
  });

  const isOpen =
    permissionsLoading ||
    profileLoading ||
    businessProfileLoading ||
    loadingFlow ||
    groupsLoading ||
    taskCategoriesLoading ||
    whiteLabelLoading ||
    !Boolean(userId);

  return <Splash open={isOpen} />;
};

export default SplashScreen;
